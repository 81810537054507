// i18next-extract-mark-ns-start products-valdyn-esm

import { VALDYN_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductValdynPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							Using its wide range of modelling elements, VALDYN is
							ideal for simulating complete engine systems. The ease
							of model construction and high simulation speed means
							that models can be assembled and solved quickly. Common
							engine components (e.g. cam followers, hydraulic lash
							adjusters, cam phasers, chains, belts, gears and
							bearings) are provided by VALDYN and other mechanisms
							can be assembled from over 30 basic building block
							elements.
						</p>
						<p>
							Co-simulation with other Realis Simulation formerly
							Ricardo Software tools – IGNITE, WAVE and ENGDYN – or
							third-party programs enables the system boundaries to be
							spread beyond the VALDYN model so that complex
							interdependencies can be examined.
						</p>
						<h2>Key features</h2>
						<ul>
							<li>Rapid generation of complete engine models</li>
							<li>Parametric input for case-to-case variation</li>
							<li>Run distribution for fast multi-case analysis</li>
							<li>
								Co-simulation with Realis Simulation formerly
								Ricardo Software’s IGNITE, WAVE and ENGDYN tools
							</li>
							<li>
								Linear frequency domain analysis including automatic
								model simplification of non-linear components
							</li>
							<li>
								Perturbation analysis to allow frequency domain
								analysis at a given instant in time
							</li>
							<li>
								Roller and journal bearings including
								elastohydrodynamic bearings
							</li>
							<li>Dynamic bodies to include any FE model casings</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{VALDYN_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductValdynPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-valdyn-esm", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/valdyn-hero.jpg" }) {
		...imageBreaker
	}
}
`;

